import React, { useState } from "react";
import { SnackbarProvider, enqueueSnackbar, useSnackbar } from "notistack";


import AuthUser from "./AuthUser";
import Spinner from "./Spinner";

import { Link } from "react-router-dom"; 

export default function MarcarConsulta({
  doctores,
  consultas,
  especialidades,
  unidades,
}) {
  const { http, url_root, logoTipo, verificarPermissao } = AuthUser();

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [genero, handleSelectGenerorChange] = useState(0);
  const [idade, setIdade] = useState(0);
  const [unidade, setUnidadeSelecionada] = useState(-1);
  const [data_consulta, setData] = useState();
  const [hora, setHora] = useState("08:00");
  const [telefone, setTelefone] = useState("");
  const [nome, setNome] = useState("");
  const [data_hoje, setDataHoje] = useState();
  const [assunto, setAssunto] = useState("");
  const [mensagem, setMensagem] = useState("");

  const [consulta_selecionada, setConsultaSelecionada] = useState(null);
  const [doctor_selecionado, setDoctorSelecionado] = useState(0);

  const [filteredDoctores, setFilteredDoctores] = useState([]);

  // Formata a data no formato 'yyyy-MM-dd'
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Adiciona zero à esquerda se necessário
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  /*
    const filterDoctores = (consultaId) => {
        if (consultaId > 0) {
           
            const relatedEspecialidades = especialidades.filter(especialidade => especialidade.curso_id == parseInt(consultaId));

            const doctorIds = relatedEspecialidades.map(especialidade => especialidade.formador_id);

            const newFilteredDoctores = doctores.filter(doctor => doctorIds.includes(doctor.id));

            setFilteredDoctores(newFilteredDoctores);

        } else {
            setFilteredDoctores(doctores);
        }
    }*/

  const filterDoctores = (consultaId) => {
    setFilteredDoctores(doctores);
  };

  const handleSelectConsultaChange = (valor) => {
    setConsultaSelecionada(valor);
    filterDoctores(valor);
  };

  const handleSelectUnidadeChange = (valor) => {
    setUnidadeSelecionada(valor);
  };

  /* const handleSelectDoctorChange = (valor) => {
        setDoctorSelecionado(valor);
    } */

  const renderOptionConsultas = (consultas) => {
    return consultas?.map((consulta) => (
      <React.Fragment key={consulta.id}>
        <option value={consulta.id}>{consulta.designacao.toUpperCase()}</option>
      </React.Fragment>
    ));
  };

  const renderOptionUnidades = (consultas) => {
    return consultas?.map((consulta) => (
      <React.Fragment key={consulta.id}>
        <option value={consulta.id}>{consulta.designacao.toUpperCase()}</option>
      </React.Fragment>
    ));
  };

  /* const renderOptionDoctores = (doctores) => {
        return doctores?.map((doctor) => (
            <React.Fragment key={doctor.id}>
                <option value={doctor.id}>{doctor.name.toUpperCase()}</option>
            </React.Fragment>
        ));
    }; */

  const handleMarcarConsulta = async () => {
    try {
      // Verificar se todos os campos estão preenchidos com mais de um caractere
      /*if (
                email.length > 1 &&
                nome.length > 1 &&
                mensagem.length > 1 &&
                idade > 0 &&
                isDateValid(data_consulta) &&
                isTimeValid(hora)
            ) {*/
      if (
        email.length > 1 &&
        nome.length > 1 &&
        mensagem.length > 1 &&
        idade > 0
      ) {
        // Verificar se o e-mail é válido
        if (!isEmailValid(email)) {
          enqueueSnackbar("Por favor, forneça um e-mail válido.", {
            variant: "error",
          });
          return;
        }

        setLoading(true);

        const currentDate = new Date();
        setDataHoje(formatDate(currentDate));

        const formData = new FormData();
        formData.append("titulo", assunto);
        formData.append("idade", idade);
        formData.append("genero", genero);
        formData.append("descricao", mensagem);
        formData.append("email", email);
        formData.append("telefone", telefone);
        formData.append("name", nome);
        formData.append("data_inscricao", data_hoje);
        formData.append("hora", "08:00");
        formData.append("formador_id", -1);
        formData.append("unidade", unidade);
        formData.append("curso_id", consulta_selecionada);
        formData.append("obs", mensagem);

        const response = await http.post("/auto-marcar-consulta", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        console.log(response);

        setLoading(false);

        enqueueSnackbar("Marcação feita com sucesso, verifique seu e-mail", {
          variant: "success",
        });

        setNome("");
        setTelefone("");
        setEmail("");
        setIdade("");
        setAssunto("");
        setMensagem("");
      } else {
        setLoading(false);
        enqueueSnackbar("Por favor, preencha todos os campos corretamente.", {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar(
        "Ocorreu um erro. Por favor, tente novamente mais tarde.",
        { variant: "error" }
      );
      setLoading(false);
    }
  };

  const isEmailValid = (email) => {
    // Expressão regular para validar e-mail
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const isDateValid = (date) => {
    const today = new Date();
    const selectedDate = new Date(date);
    return selectedDate > today;
  };

  const isTimeValid = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    const selectedTime = new Date();
    selectedTime.setHours(hours, minutes, 0);
    const openingTime = new Date();
    openingTime.setHours(8, 0, 0);
    const closingTime = new Date();
    closingTime.setHours(17, 0, 0);
    return selectedTime >= openingTime && selectedTime <= closingTime;
  };

  return (
    <>
      <div className="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div className="card border-0 rounded shadow p-4 ms-xl-3">
          <div className="custom-form">
            <form>
              <div className="row">
                <div className="col-lg-12">
                  <div className="mb-3">
                    <label className="form-label">
                      {" "}
                      Nome Do Paciente <span className="text-danger"> * </span>
                    </label>
                    <input
                      name="name"
                      id="name1"
                      type="text"
                      className="form-control"
                      placeholder="Nome do paciente :"
                      value={nome}
                      onChange={(e) => setNome(e.target.value)}
                    />
                  </div>{" "}
                </div>
                <div className="col-lg-12">
                  <div className="mb-3">
                    <label className="form-label"> Consultas </label>{" "}
                    <select
                      onChange={(e) =>
                        handleSelectConsultaChange(e.target.value)
                      }
                      value={consulta_selecionada}
                      className="form-select form-control"
                    >
                      <option value={-1}>Selecione o tipo de consulta</option>
                      {renderOptionConsultas(consultas)}
                    </select>{" "}
                  </div>{" "}
                </div>
                {/* <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="form-label"> Especialista </label>{" "}
                                        <select
                                            onChange={(e) => handleSelectDoctorChange(e.target.value)}
                                            value={doctor_selecionado}
                                            className="form-select form-control"
                                        >
                                            <option value={-1}>Selecione o especialista</option>
                                            {renderOptionDoctores(filteredDoctores)}

                                        </select>{" "}
                                    </div>{" "}
                                </div> */}
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label">
                      {" "}
                      Seu E-mail <span className="text-danger"> * </span>
                    </label>
                    <input
                      name="email"
                      id="email"
                      type="email"
                      className="form-control"
                      placeholder="Seu E-mail :"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>{" "}
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label">
                      {" "}
                      Seu Telefone <span className="text-danger"> * </span>
                    </label>
                    <input
                      name="phone"
                      id="phone"
                      type="tel"
                      className="form-control"
                      placeholder="Seu telefone :"
                      value={telefone}
                      onChange={(e) => setTelefone(e.target.value)}
                    />
                  </div>{" "}
                </div>
                <div className="col-lg-12">
                  <div className="mb-3">
                    <label className="form-label"> Unidade </label>{" "}
                    <select
                      onChange={(e) =>
                        handleSelectUnidadeChange(e.target.value)
                      }
                      value={unidade}
                      className="form-select form-control"
                    >
                      <option value={-1}>Selecione a unidade</option>
                      {renderOptionUnidades(unidades)}
                    </select>{" "}
                  </div>{" "}
                </div>
                {/* <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="form-label">
                                            {" "}
                                            Data <span className="text-danger"> * </span>
                                        </label>
                                        <input
                                            name="date"
                                            type="date"
                                            className="form-control start"
                                            placeholder="Selecione Uma Data :"
                                            value={data_consulta}
                                            onChange={(e) => setData(e.target.value)}
                                        />
                                    </div>{" "}
                                </div> */}
                {/* <div className="col-lg-6">
                                    <div className="mb-3">
                                        <label className="form-label" for="input-time">
                                            {" "}
                                            Hora <span className="text-danger"> * </span>
                                        </label>
                                        <input
                                            name="hora"
                                            type="time"
                                            className="form-control timepicker"
                                            id="input-time"
                                            placeholder="07:30"
                                            value={hora}
                                            onChange={(e) => setHora(e.target.value)}
                                        />
                                    </div>{" "}
                                </div> */}
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label"> Idade </label>{" "}
                    <input
                      name="idade"
                      type="number"
                      className="form-control timepicker"
                      id="input-time"
                      placeholder="7"
                      value={idade}
                      onChange={(e) => setIdade(e.target.value)}
                    />{" "}
                  </div>{" "}
                </div>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <label className="form-label"> Genero </label>{" "}
                    <select
                      onChange={(e) =>
                        handleSelectGenerorChange(e.target.value)
                      }
                      value={genero}
                      className="form-select form-control"
                    >
                      <option value={0}>Selecione o genero</option>
                      <option value={2}>Feminino</option>
                      <option value={1}>Masculino</option>
                    </select>{" "}
                  </div>{" "}
                </div>
                <div className="col-lg-12">
                  <div className="mb-3">
                    <label className="form-label">
                      {" "}
                      Nota <span className="text-danger"> * </span>
                    </label>
                    <textarea
                      name="comments"
                      id="comments"
                      rows="4"
                      className="form-control"
                      placeholder="Digite aqui os sintomas para melhor orientação sobre que consulta devera fazer :"
                      value={mensagem}
                      onChange={(e) => setMensagem(e.target.value)}
                    >
                      {" "}
                    </textarea>{" "}
                  </div>{" "}
                </div>
                <div className="col-lg-6">
                  <div className="d-grid">
                    <span
                      id="submit"
                      name="send"
                      className="btn btn-primary"
                      onClick={() => handleMarcarConsulta()}
                    >
                      Marcar Consulta
                    </span>
                  </div>{" "}
                </div>{" "}
                <div className="col-lg-6">
                  <div className="d-grid">
                    <Link to="https://wa.me/244957672765">
                      {" "}
                      <span
                        title="Enviar documentos pelo whatsapp"
                        className="btn btn-success"
                      >
                        <i className="fa fa-file-o"></i> Enviar Documentos
                      </span>
                    </Link>
                  </div>
                </div>
              </div>{" "}
            </form>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
      {loading ? (
        <>
          <Spinner />
        </>
      ) : (
        <></>
      )}
    </>
  );
}
